<template>
  <div
    class="authorization-form"
    :class="{ mobile: props.isMobile }"
    data-t="authorization-form"
  >
    <FirstStep
      v-if="step === 0"
      :is-mobile="props.isMobile"
      @success="handleFirstStepSuccess"
      @login="handleSuccess"
      @go-to-recovery="handleRecovery"
      @go-to-registration="handleRegistration"
    />
    <SecondStep
      v-if="step === 1"
      :data="authData"
      :is-mobile="props.isMobile"
      @success="handleSuccess"
      @back="goToFirstStep"
    />
  </div>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import FirstStep from './parts/FirstStep.vue'
import SecondStep from './parts/SecondStep.vue'

interface AuthorizationData {
  email: string
  password: string
}

const props = withDefaults(
  defineProps<{
    isMobile?: boolean
  }>(),
  {
    isMobile: false,
  },
)
const { fetchGeoAvailability } = useCheckGeoAvailability()

const router = useRouter()

const step = ref(0)
const authData = ref<AuthorizationData>({ email: '', password: '' })

const emit = defineEmits<{
  (e: 'goToRecovery', value: string): void
  (e: 'goToRegistration'): void
  (e: 'success'): void
  (e: 'stepChanged', value: number): void
}>()

function handleFirstStepSuccess(data: AuthorizationData) {
  step.value += 1
  authData.value = data
  emit('stepChanged', step.value)
}

function handleRecovery(email: string) {
  emit('goToRecovery', email)
}

function handleRegistration() {
  emit('goToRegistration')
}

function goToFirstStep() {
  step.value -= 1
  emit('stepChanged', step.value)
}

const route = useRoute()
function handleSuccess() {
  router.replace({ query: {} })
  if (route.path.includes('promo/registration')) {
    router.push('/')
  }
  emit('success')
}

onMounted(async () => {
  await delay(500)
  fetchGeoAvailability()
})
</script>

<style scoped>
.authorization-form {
  display: flex;
  justify-content: center;

  width: 480px;
  min-height: 444px;
  padding: var(--spacing-500) var(--spacing-600);

  &.mobile {
    width: 100%;
    height: 100%;
    margin-top: var(--spacing-200);
    padding: var(--spacing-200);
    padding-top: 0;
  }
}
</style>
